import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 84px;
  padding: 18px 0 18px 58px;
  background: #fff;
  box-shadow: 0px 4px 8px rgba(46, 46, 46, 0.08);

  img {
    height: 48px;
  }
`;
