import { useCallback, useState } from 'react';

const useToggle = (initialValue: boolean): [boolean, () => void] => {
  const [value, setValue] = useState<boolean>(initialValue);

  const toggleValue = useCallback(() => setValue(!value), [value]);

  return [value, toggleValue];
};

export default useToggle;
