import React, { createContext } from 'react';

import useAccounts from '../hooks/useAccounts';
import { IUser } from '../entities/User';
import { ICompany } from '../entities/Company';

interface AccountsContextData {
  user: IUser | undefined;
  setUser?: any;
  company: ICompany | undefined;
  setCompany: any;
  forceToReload: number;
  setForceToReload: any;
  disableUploadPersonalDocuments: boolean;
  setDisableUploadPersonalDocuments: any;
  disableUploadResidenceProveDocuments: boolean;
  setDisableUploadResidenceProveDocuments: any;
  disableUploadCompanyAddressProveDocuments: boolean;
  setDisableUploadCompanyAddressProveDocuments: any;
  disableUploadCompanyBankAccountDocuments: boolean;
  setDisableUploadCompanyBankAccountDocuments: any;
  disableUploadCompanyDocuments: boolean;
  setDisableUploadCompanyDocuments: any;
  forceToReloadBankComponent: number;
  setForceToReloadBankComponent: any;
  mobileMenuToggle: boolean;
  setMobileMenuToggle: any;
  uploadSuccess: boolean;
  setUploadSuccess: any;
  siriusLoadingSync: number;
  setSiriusLoadingSync: React.Dispatch<React.SetStateAction<number>>;
  lastVerificationPixKey:any;
  setLastVerificationPixKey:any;
}

const AccountsContext = createContext<AccountsContextData>(
  {} as AccountsContextData,
);

const AccountsProvider: React.FC = ({ children }) => {
  const {
    user,
    setUser,
    company,
    setCompany,
    forceToReload,
    setForceToReload,
    disableUploadPersonalDocuments,
    setDisableUploadPersonalDocuments,
    disableUploadResidenceProveDocuments,
    setDisableUploadResidenceProveDocuments,
    disableUploadCompanyAddressProveDocuments,
    setDisableUploadCompanyAddressProveDocuments,
    disableUploadCompanyBankAccountDocuments,
    setDisableUploadCompanyBankAccountDocuments,
    disableUploadCompanyDocuments,
    setDisableUploadCompanyDocuments,
    forceToReloadBankComponent,
    setForceToReloadBankComponent,
    mobileMenuToggle,
    setMobileMenuToggle,
    uploadSuccess,
    setUploadSuccess,
    siriusLoadingSync,
    setSiriusLoadingSync,
    lastVerificationPixKey,
    setLastVerificationPixKey,
  } = useAccounts();

  return (
    <AccountsContext.Provider
      value={{
        setUser,
        user,
        company,
        setCompany,
        forceToReload,
        setForceToReload,
        disableUploadPersonalDocuments,
        setDisableUploadPersonalDocuments,
        disableUploadResidenceProveDocuments,
        setDisableUploadResidenceProveDocuments,
        disableUploadCompanyAddressProveDocuments,
        setDisableUploadCompanyAddressProveDocuments,
        disableUploadCompanyBankAccountDocuments,
        setDisableUploadCompanyBankAccountDocuments,
        disableUploadCompanyDocuments,
        setDisableUploadCompanyDocuments,
        forceToReloadBankComponent,
        setForceToReloadBankComponent,
        mobileMenuToggle,
        setMobileMenuToggle,
        uploadSuccess,
        setUploadSuccess,
        siriusLoadingSync,
        setSiriusLoadingSync,
        lastVerificationPixKey,
        setLastVerificationPixKey,
      }}
    >
      {children}
    </AccountsContext.Provider>
  );
};

export { AccountsContext, AccountsProvider };
