import React, { useState, useCallback, useRef, useEffect } from 'react';
import { CSSProperties } from 'styled-components';
import { Container, DropdownMenu, Button } from './styles';

interface DropdownProps {
  icon?: string;
  buttonText?: string;
  className?: string;
  trigger?: any;
  btnStyle?: CSSProperties;
}

const Dropdown: React.FC<DropdownProps> = ({
  children,
  icon,
  buttonText,
  className,
  trigger,
  btnStyle,
}) => {
  const [show, setShow] = useState<boolean>(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback((e) => {
    if (!menuRef.current?.contains(e.target)) {
      setShow(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  const handleToggle = useCallback(() => {
    setShow(!show);
  }, [show]);

  return (
    <Container ref={menuRef} className={className}>
      <Button
        type="button"
        className="dropdown-button"
        onClick={handleToggle}
        style={btnStyle}
      >
        {icon && <img src={icon} alt="" />}
        {buttonText && buttonText}
        {trigger && trigger}
      </Button>
      <DropdownMenu className="dropdown-menu" show={show}>
        {children}
      </DropdownMenu>
    </Container>
  );
};

export default Dropdown;
