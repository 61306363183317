import React from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { Container, IconContainer, Button } from './styles';

interface GoBackButtonProps {
  className?: string;
  onClick?: () => void;
}

const GoBackButton: React.FC<GoBackButtonProps> = ({ onClick, className }) => {
  return (
    <Container>
      <Button className={className} onClick={onClick}>
        <IconContainer>
          <FiArrowLeft size={12} color="#fff" />
        </IconContainer>
        <p>voltar</p>
      </Button>
    </Container>
  );
};

export default GoBackButton;
