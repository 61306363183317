import React, { InputHTMLAttributes }  from 'react';
import {} from 'react-select';
import { Container, Label, SelectInput } from './styles';

import ErrorMessage from '../ErrorMessage';

interface AdminSelectInputProps  extends InputHTMLAttributes<HTMLSelectElement>  {
  id?: string;
  className?: string;
  selectClassName?: string;
  name?: string;
  placeholder?: string;
  value?: any;
  label?: string;
  error?: string;
  errorMessage?: string;
  disabled?: boolean;
  register?: any;
  onChange?: (arg0: any) => void;
  options?: Array<object>;
}

const AdminSelectInput: React.FC<AdminSelectInputProps> = ({
  id,
  className,
  selectClassName,
  name,
  placeholder,
  value,
  label,
  disabled,
  onChange,
  children,
  options,
  errorMessage,
  register,
}) => {
  return (
    <Container className={className}>
      {label && <Label htmlFor={id}>{label}</Label>}
      <SelectInput
        id={id}
        name={name}
        placeholder="Selecionar"
        className={selectClassName}
        value={value}
        isDisabled={disabled}
        // disabled={disabled}
        onChange={onChange}
        classNamePrefix="accounts"
        options={options}
        ref={register}
      >
        {children}
      </SelectInput>

      {errorMessage && (
        <ErrorMessage marginTop="7px">{errorMessage}</ErrorMessage>
      )}
    </Container>
  );
};

export default AdminSelectInput;
