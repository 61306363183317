import { useCallback, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import api from '../services/api';
import { AccountsContext as Context } from '../context/AccountsContext';

const useFileUpload = () => {
  const { setUser } = useContext(Context);
  const [loadingStatus, setLoadingStatus] = useState<number>(0);
  const [uploadStatus, setUploadStatus] = useState<boolean>(false);
  const [response, setResponse] = useState<any>({});

  // validateFile(file);
  const uploadFile = useCallback(
    async (file: any, documentType: string, apiEndpoint: string) => {
      setLoadingStatus(1);
      const formData = new FormData();
      formData.append('file_to_upload', file);
      formData.append('document_type', documentType);

      try {
        const res = await api.post(apiEndpoint, formData);
        const userRes = res.data.data;

        setUser(userRes);
        setResponse(res.data.data);
        toast.success('Arquivo enviado com sucesso!');
        setUploadStatus(true);
        setLoadingStatus(0);
      } catch (error) {
        setLoadingStatus(0);
        toast.error(error.response.data.message);
      }
    },
    [setUser],
  );

  return { loadingStatus, uploadFile, uploadStatus, response };
};

export default useFileUpload;
