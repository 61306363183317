import styled from 'styled-components';
import * as color from '../../../../styles/colors';

export const Container = styled.div`
  position: relative;
  .goback-button {
    margin-bottom: 20px;
    p {
      color: ${color.orange};
    }
  }
`;
