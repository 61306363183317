import styled, { keyframes } from 'styled-components';
import usernameImg from '../../assets/img/highfive.jpeg';
import computerImg from '../../assets/img/computer.jpeg';
import phoneImg from '../../assets/img/phone.jpeg';
import safeboxImg from '../../assets/img/safebox.jpeg';
import EmailCheckImg from '../../assets/img/email-check.jpg';
import startupImg from '../../assets/img/startup.jpg';
import bookImg from '../../assets/img/book.jpg';
import cpfBackground from '../../assets/backgrounds/id-card-animate.svg';
import zipcodeBackground from '../../assets/backgrounds/navigation-animate.svg';
import residenceDocBackground from '../../assets/backgrounds/upload-animate.svg';
import idDocBackground from '../../assets/backgrounds/image-upload-animate.svg';
import selfieBackground from '../../assets/backgrounds/photo-animate.svg';
import bankAccountTypeBackground from '../../assets/backgrounds/setup-analytics-animate.svg';
import walletBackground from '../../assets/backgrounds/wallet-animate.svg';
import reportBackground from '../../assets/backgrounds/report-animate.svg';
import agreementBackground from '../../assets/backgrounds/agreement-animate.svg';
import officeBackground from '../../assets/backgrounds/on-the-office-animate.svg';
import visualDataBackground from '../../assets/backgrounds/visual-data-animate.svg';
import tutorialBackground from '../../assets/img/computer-set3.png';

import randomBackground1 from '../../assets/img/random-background/background-1.jpg';
import randomBackground2 from '../../assets/img/random-background/background-2.jpg';
import randomBackground3 from '../../assets/img/random-background/background-3.jpg';
import randomBackground4 from '../../assets/img/random-background/background-4.jpg';
import randomBackground5 from '../../assets/img/random-background/background-5.jpg';
import randomBackground6 from '../../assets/img/random-background/background-6.jpg';
import randomBackground7 from '../../assets/img/random-background/background-7.jpg';
import randomBackground8 from '../../assets/img/random-background/background-8.jpg';
import randomBackground9 from '../../assets/img/random-background/background-9.jpg';
import randomBackground10 from '../../assets/img/random-background/background-10.jpg';

interface ContainerProps {
  showLogo?: boolean;
  topbar?: boolean;
}

export const Container = styled.div<ContainerProps>`
  height: 100vh;
  align-items: stretch;

  .topbar {
    display: ${({ topbar }) => (topbar ? 'block' : 'none')};
  }
`;

const fadeIn = keyframes`
  0% {
    display: block;
    opacity: 0;
  }
  99% {
    opacity: 1;
  }
`

export const PageContainer = styled.div<ContainerProps>`
  .page-bkg {
    display: none;
    animation: 2s ${fadeIn};
    animation-fill-mode: forwards; 
  }
  @media (min-width: 769px) {
    height: ${({ topbar }) => (topbar ? 'calc(100% - 84px)' : '100vh')};
    display: flex;
    align-items: stretch;

    .page-bkg {
      display: block;
    }
  }
`;

export const Content = styled.div<ContainerProps>`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  padding-top: ${({ topbar }) => (topbar ? '40px' : '0')};

  width: 700px;
  max-width: 700px;

  .content-logo {
    display: ${({ showLogo }) => (showLogo ? 'block' : 'none')};
    margin-top: 70px;
    margin-bottom: 40px;
    width: 300px;
  }

  .scroll-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 70px 20px 70px;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
  }

  .goback-button {
    margin-top: 10px;
  }

  @media (max-width: 769px) {
    /* justify-content: center; */
    width: 100%;

    .content-logo {
      margin-top: 30px;
      margin-bottom: 15px;
    }

    .scroll-container {
      padding: 20px 10px 20px 10px; 
    }

  }
`;

export const UsernameBackground = styled.div`
  flex: 1;
  background: url(${usernameImg}) no-repeat center;
  background-size: cover;
`;

export const ComputerBackground = styled.div`
  flex: 1;
  background: url(${computerImg}) no-repeat center;
  background-size: cover;
`;

export const PhoneBackground = styled.div`
  flex: 1;
  background: url(${phoneImg}) no-repeat center;
  background-size: cover;
`;

export const SafeboxBackground = styled.div`
  flex: 1;
  background: url(${safeboxImg}) no-repeat center;
  background-size: cover;
`;

export const EmailBackground = styled.div`
  flex: 1;
  background: url(${EmailCheckImg}) no-repeat center;
  background-size: cover;
`;

export const CpfBackground = styled.div`
  flex: 1;
  background: url(${cpfBackground}) no-repeat center;
`;

export const ZipcodeBackground = styled.div`
  flex: 1;
  background: url(${zipcodeBackground}) no-repeat center;
`;

export const ResidenceDocBackground = styled.div`
  flex: 1;
  background: url(${residenceDocBackground}) no-repeat center;
`;

export const IdDocBackground = styled.div`
  flex: 1;
  background: url(${idDocBackground}) no-repeat center;
`;

export const SelfieBackground = styled.div`
  flex: 1;
  background: url(${selfieBackground}) no-repeat center;
`;

export const BankAccountTypeBackground = styled.div`
  flex: 1;
  background: url(${bankAccountTypeBackground}) no-repeat center;
`;

export const WalletBackground = styled.div`
  flex: 1;
  background: url(${walletBackground}) no-repeat center;
`;

export const ReportBackground = styled.div`
  flex: 1;
  background: url(${reportBackground}) no-repeat center;
`;

export const AgreementBackground = styled.div`
  flex: 1;
  background: url(${agreementBackground}) no-repeat center;
`;

export const OfficeBackground = styled.div`
  flex: 1;
  background: url(${officeBackground}) no-repeat center;
`;

export const VisualDataBackground = styled.div`
  flex: 1;
  background: url(${visualDataBackground}) no-repeat center;
`;

export const NavigationBackground = styled.div`
  flex: 1;
  background: url(${zipcodeBackground}) no-repeat center;
`;

export const UploadBackground = styled.div`
  flex: 1;
  background: url(${idDocBackground}) no-repeat center;
  background-size: cover;
`;

export const BookImgBackground = styled.div`
  flex: 1;
  background: url(${bookImg}) no-repeat center;
  background-size: cover;
`;

export const StartupImgBackground = styled.div`
  flex: 1;
  background: url(${startupImg}) no-repeat center;
  background-size: cover;
`;

export const TutorialBackground = styled.div`
  flex: 1;
  background: url(${tutorialBackground}) no-repeat center;
  background-size: cover;
`;

export const RandomBackground1 = styled.div`
  flex: 1;
  background: url(${randomBackground1}) no-repeat center;
  background-size: cover;
`;

export const RandomBackground2 = styled.div`
  flex: 1;
  background: url(${randomBackground2}) no-repeat center;
  background-size: cover;
`;

export const RandomBackground3 = styled.div`
  flex: 1;
  background: url(${randomBackground3}) no-repeat center;
  background-size: cover;
`;

export const RandomBackground4 = styled.div`
  flex: 1;
  background: url(${randomBackground4}) no-repeat center;
  background-size: cover;
`;

export const RandomBackground5 = styled.div`
  flex: 1;
  background: url(${randomBackground5}) no-repeat center;
  background-size: cover;
`;

export const RandomBackground6 = styled.div`
  flex: 1;
  background: url(${randomBackground6}) no-repeat center;
  background-size: cover;
`;

export const RandomBackground7 = styled.div`
  flex: 1;
  background: url(${randomBackground7}) no-repeat center;
  background-size: cover;
`;

export const RandomBackground8 = styled.div`
  flex: 1;
  background: url(${randomBackground8}) no-repeat center;
  background-size: cover;
`;

export const RandomBackground9 = styled.div`
  flex: 1;
  background: url(${randomBackground9}) no-repeat center;
  background-size: cover;
`;

export const RandomBackground10 = styled.div`
  flex: 1;
  background: url(${randomBackground10}) no-repeat center;
  background-size: cover;
`;
