import React, {
  InputHTMLAttributes,
  CSSProperties,
  useState,
  useCallback,
} from 'react';
import { FiCheck, FiX, FiEye } from 'react-icons/fi';
import * as color from '../../styles/colors';
import ErrorMessage from '../ErrorMessage';
import Button from '../Button';
import { Container, Label, Input } from './styles';

interface InputsProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  status?: number;
  errorMessage?: string | React.ReactNode;
  register?: any;
  control?: any;
  setValue?: any;
  disabled?: boolean;
  inputClassName?: string;
  mask?: string;
}

const PrimaryInput: React.FC<InputsProps> = ({
  className,
  id,
  label,
  register,
  inputClassName,
  placeholder,
  type,
  status,
  disabled,
  name,
  errorMessage,
  ...rest
}) => {
  const iconStyle: CSSProperties = {
    position: 'absolute',
    top: '40px',
    left: '25px',
  };

  const [passwordType, setPasswordType] = useState(type);

  const handleShow = useCallback(() => {
    if (passwordType === 'password') {
      setPasswordType('text');
    } else {
      setPasswordType(type);
    }
  }, [passwordType, type]);

  return (
    <Container className={className}>
      {label && <Label htmlFor={id}>{label}</Label>}
      {status === 1 && (
        <FiCheck color={color.green} size={24} style={iconStyle} />
      )}
      {status === 0 && <FiX color={color.red} size={24} style={iconStyle} />}

      <Input
        status={status}
        placeholder={placeholder}
        className={inputClassName}
        type={type === 'password' ? passwordType : type}
        disabled={disabled}
        name={name}
        ref={register}
        {...rest}
      />

      {errorMessage && (
        <ErrorMessage marginTop="7px">{errorMessage}</ErrorMessage>
      )}

      {type === 'password' && (
        <Button
          transparent
          className="password-button"
          type="button"
          onClick={() => handleShow()}
        >
          <FiEye size={20} color="#b3b5c0" />
        </Button>
      )}
    </Container>
  );
};

export default PrimaryInput;
