import React, { useCallback, useState } from 'react';
import { FiXCircle, FiCheckCircle } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { Container } from './styles';
import Button from '../../../../components/Button';
import Loader from '../../../../components/Loader';
import api from '../../../../services/api';
import * as color from '../../../../styles/colors';

interface CompanyDeleteProps {
  id?: string;
  closeModal?: any;
  status?: number;
}

const CompanyStatusFlagContent: React.FC<CompanyDeleteProps> = ({
  id,
  closeModal,
  status,
}) => {
  const [loading, setLoading] = useState(0);

  const handleChangeStatusFlag = useCallback(async () => {
    try {
      setLoading(1);

      await api.put(`/companies/change-status/${id}`);
      setLoading(0);

      closeModal('flag');
      toast.success('Atualizado com sucesso!');
    } catch (error) {
      setLoading(0);
    }
  }, [closeModal, id]);
  return (
    <Container>
      <Loader loading={loading} />
      {status ? (
        <FiXCircle size={50} color="#ff4c52" />
      ) : (
        <FiCheckCircle size={50} color={color.green} />
      )}
      <p className="question">Você tem certeza?</p>
      <p className="phrase">
        {status ? 'Deseja inativar a empresa?' : 'Deseja ativar a empresa?'}
      </p>
      <div className="buttons-container">
        <Button className="cancel-button" onClick={() => closeModal('flag')}>
          Cancelar
        </Button>
        <Button className="delete-button" onClick={handleChangeStatusFlag}>
          Confirmar
        </Button>
      </div>
    </Container>
  );
};

export default CompanyStatusFlagContent;
