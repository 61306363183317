import React from 'react';
import { Container } from './styles';

interface BadgeProps {
  status?: string;
  className?: string;
}

const Badge: React.FC<BadgeProps> = ({ status, className }) => {
  return (
    <Container status={status} className={className}>
      {status}
    </Container>
  );
};

export default Badge;
