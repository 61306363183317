import React, { useCallback, useRef, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import Cropper from 'react-cropper';
import api from '../../../../../services/api';
import {
  Container,
  Row,
  CropperContainer,
  UploaderButton,
  PreviewContainer,
  CancelButton,
  SaveButton,
} from './styles';
import Loader from '../../../../../components/Loader';
import { AccountsContext as Context } from '../../../../../context/AccountsContext';

interface ImageCropProps {
  closeModal?: any;
}

const ImageCrop: React.FC<ImageCropProps> = ({ closeModal }) => {
  const cropperRef = useRef<HTMLImageElement>(null);
  const [image, setImage] = useState<any>();
  const [cropper, setCropper] = useState<any>();
  const [loading, setLoading] = useState<number>(0);
  const { setUser } = useContext(Context);

  const handleChange = useCallback((e: any) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);
    };
    reader.readAsDataURL(files[0]);
  }, []);

  // will define and save the crop image
  const getCropData = useCallback(async () => {
    if (typeof cropper !== 'undefined') {
      setLoading(1);
      const formData = new FormData();

      formData.append('profile_photo', image);
      formData.append('photo_x', cropper.getData().x);
      formData.append('photo_y', cropper.getData().y);
      formData.append('photo_w', cropper.getData().width);
      formData.append('photo_h', cropper.getData().height);

      try {
        const res = await api.post('profile/upload-image', formData);
        setUser(res.data.data);
        setLoading(0);
        closeModal(true);
        toast.success('Sua foto de perfil foi alterada com sucesso!');
      } catch (error) {
        setLoading(0);
        toast.error('Ops! Algo saiu errado, por favor tente novamente.');
      }
    }
  }, [closeModal, cropper, image, setUser]);

  return (
    <Container>
      <Loader loading={loading} />
      <Row>
        <CropperContainer>
          <Cropper
            src={image}
            style={{ height: 300, width: 400 }}
            initialAspectRatio={9 / 9}
            aspectRatio={5 / 5}
            viewMode={1}
            background={false}
            responsive
            preview=".img-preview"
            guides
            minCropBoxHeight={50}
            minCropBoxWidth={50}
            ref={cropperRef}
            className="cropper"
            onInitialized={(instance) => {
              setCropper(instance);
            }}
          />

          <UploaderButton htmlFor="uploader">Escolher imagem</UploaderButton>
          <input
            type="file"
            className="image-uploader"
            id="uploader"
            onChange={handleChange}
          />
        </CropperContainer>
        <PreviewContainer>
          <div className="img-preview" />
        </PreviewContainer>
      </Row>
      <Row className="buttons-row">
        <CancelButton onClick={() => closeModal(true)}>Cancelar</CancelButton>
        <SaveButton onClick={getCropData}>Salvar</SaveButton>
      </Row>
    </Container>
  );
};

export default ImageCrop;
