import styled from 'styled-components';

export const Container = styled.div`
  .modal-content {
    height: 400px;
    overflow: auto;
  }

  .access-terms-row {
    margin: 25px 0 0;
    justify-content: space-between;

    .active-access {
      display: flex;
      .active,
      .last-access {
        /* line-height: 16px; */
      }
    }

    .terms {
      button {
        color: blue;
      }
    }
  }
`;

export const ModalContent = styled.div`
  height: 400px;
  overflow: auto;
  margin-top: 15px;
`;
