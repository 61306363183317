import { useEffect, useState } from 'react';

const useUrlSearchParams = (initialValue: string) => {
  const [value, setValue] = useState<string>('');
  useEffect(() => {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const codeFromURL = params.get(initialValue);
    codeFromURL && setValue(codeFromURL);
  }, [initialValue, value]);

  return value;
};

export default useUrlSearchParams;
