import React, { useCallback, useState } from 'react';
import { FiXCircle } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { Container } from './styles';
import Button from '../../../../components/Button';
import Loader from '../../../../components/Loader';
import api from '../../../../services/api';

interface CollaboratorDeleteProps {
  id?: any;
  closeModal?: any;
}

const CollaboratorDeleteModalContent: React.FC<CollaboratorDeleteProps> = ({
  id,
  closeModal,
}) => {
  const [loading, setLoading] = useState<number>(0);

  const handleDeleteCollaborator = useCallback(async () => {
    setLoading(1);
    try {
      await api.delete(`/collaborators/${id}`);
      closeModal('true');
      setLoading(0);

      toast.success('Colaborador removido com sucesso!');
    } catch (error) {
      setLoading(0);
    }
  }, [closeModal, id]);

  return (
    <Container>
      <Loader loading={loading} />
      <FiXCircle size={50} color="#ff4c52" />
      <p className="question">Você tem certeza?</p>
      <p className="phrase">
        Se você excluir esse registro, não será possível recuperá-lo!
      </p>
      <div className="buttons-container">
        <Button className="cancel-button" onClick={() => closeModal('true')}>
          Cancelar
        </Button>
        <Button className="delete-button" onClick={handleDeleteCollaborator}>
          Excluir
        </Button>
      </div>
    </Container>
  );
};

export default CollaboratorDeleteModalContent;
