import React from 'react';
import {
  CardContainer,
  CardFooter,
  ContentText,
  RedCircle,
  Icon,
} from './styles';
import Title from '../Title';

interface SmallCardProps {
  title?: string;
  contenText?: string;
  footerText?: string;
  icon?: string;
  redCircle?: boolean;
  onlyIcon?: boolean;
  className?: string;
  footerOnClick?: () => void;
  cardOnClick?: () => void;
}

const SmallCard: React.FC<SmallCardProps> = ({
  title,
  contenText,
  footerText,
  icon,
  redCircle,
  onlyIcon,
  children,
  className,
  footerOnClick,
  cardOnClick,
}) => {
  return (
    <CardContainer className={className} onClick={cardOnClick}>
      {title && (
        <Title secondary className="card-title">
          {title}
        </Title>
      )}
      {children}
      {contenText && <ContentText secondary>{contenText}</ContentText>}
      <CardFooter onClick={footerOnClick} className="card-footer">
        {/* has to be a Link here */}
        <ContentText primary>{footerText}</ContentText>
      </CardFooter>
      {onlyIcon && <Icon src={icon} />}
      {redCircle && (
        <RedCircle>
          <img src={icon} alt="" />
        </RedCircle>
      )}
    </CardContainer>
  );
};

export default SmallCard;
