import React,{useEffect,useState} from 'react';
import { Container, BoldLabel } from './styles';

interface CheckboxInputProps {
  id?: string;
  name?: string;
  value?: string;
  label?: string;
  boldLabel?: string;
  htmlFor?: string;
  className?: string;
  checked?: boolean;
  onChange?: (e?: any) => void;
  disable?:boolean;
  partial?:boolean;
}

const CheckboxInput: React.FC<CheckboxInputProps> = ({
  id,
  name,
  value,
  label,
  boldLabel,
  htmlFor,
  className,
  checked,
  onChange,
  disable,
  partial
}) => {
  const [isSwitch, setIsSwitch] = useState<boolean>(checked || false);
  useEffect(()=>{
    setIsSwitch(checked||false);
  },[checked]);

  return (
    <Container className={className} partial={partial||false} disable={disable||false}>
      <input
        type="checkbox"
        id={id}
        name={name}
        value={value}
        // defaultChecked={checked}  
        checked={isSwitch}      
        onClick={() => setIsSwitch(!isSwitch)}
        onChange={onChange}
        disabled={disable||false}
      />
      <label htmlFor={htmlFor}>
        {label} &nbsp;
        <BoldLabel>{boldLabel}</BoldLabel>
      </label>
    </Container>
  );
};

export default CheckboxInput;
