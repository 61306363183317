import styled from 'styled-components';

export const Container = styled.div`
  max-height: 400px;
  overflow: auto;

  .eye-button {
    height: 28px;
  }

  .no-file-message {
    padding: 20px;
    display: flex;
    justify-content: center;

    h1 {
      font-size: 20px;
    }
  }

  th,
  td {
    text-align: center;
    text-align: -moz-center;
    text-align: -webkit-center;
  }
`;
