import styled from 'styled-components';

export const Container = styled.div`
  p {
    margin-bottom: 10px;
  }

  strong {
    font-weight: bold;
  }
`;
