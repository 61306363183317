import React from 'react';
import { Container, ContentText, Title, SubTitle } from './styles';

interface PageTopbarProps {
  title?: string;
  subTitle?: string;
}

const PageTopbar: React.FC<PageTopbarProps> = ({
  children,
  title,
  subTitle,
}) => {
  return (
    <Container>
      <ContentText>
        {children}
        <Title>{title}</Title>
        <SubTitle>{subTitle}</SubTitle>
      </ContentText>
    </Container>
  );
};

export default PageTopbar;
