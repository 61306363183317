import styled from 'styled-components';
import { shade } from 'polished';
import * as color from '../../styles/colors';

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${color.orange};
  background: transparent;
  cursor: pointer;
  text-decoration: none;
  border: none;

  &:hover {
    color: ${shade(0.2, color.orange)};
  }
`;

export const IconContainer = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 10px;
  background: ${color.orange};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`;
