import React from 'react';
import { Container } from './styles';

interface ErrorMessageProps {
  marginTop?: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ children, marginTop }) => {
  return <Container marginTop={marginTop}>{children}</Container>;
};

export default ErrorMessage;
