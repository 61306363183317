import React from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { Container, IconContainer } from './styles';

interface GoBackLinkProps {
  className?: string;
}

interface LinkProps {
  to?: any;
}

const GoBackLink: React.FC<GoBackLinkProps & LinkProps> = ({
  to,
  className,
}) => {
  return (
    <Container className={className} to={to}>
      <IconContainer>
        <FiArrowLeft size={12} color="#fff" />
      </IconContainer>

      <p>voltar</p>
    </Container>
  );
};

export default GoBackLink;
