import React from 'react';
import { CSSProperties } from 'styled-components';
import { Container } from './styles';
import Title from '../Title';
import Loader from '../Loader';

interface CardProps {
  padding?: string;
  style?: CSSProperties;
  className?: string;
  cardTitle?: string;
  width?: string;
  margin?: string;
  loading?: number;
  loadingText?: string;
  onClick?: () => void;
}

const Card: React.FC<CardProps> = ({
  children,
  padding,
  style,
  className,
  cardTitle,
  width,
  margin,
  loading,
  loadingText,
  onClick,
}) => {
  return (
    <Container
      className={`card ${className || ''}`}
      padding={padding}
      style={style}
      width={width}
      margin={margin}
      onClick={onClick}
    >
      <Loader loading={loading} loadingText={loadingText} />
      {cardTitle && (
        <Title secondary className="card-title">
          {cardTitle}
        </Title>
      )}
      {children}
    </Container>
  );
};

export default Card;
