import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import api from '../services/api';

const useCompanyFileUpload = () => {
  const [companyData, setCompanyData] = useState<{}>();
  const [loadingStatus, setLoadingStatus] = useState<number>(0);

  const uploadFile = useCallback(
    async (file: any, documentType: string, id: string) => {
      setLoadingStatus(1);
      const formData = new FormData();
      formData.append('file_to_upload', file);
      formData.append('document_type', documentType);

      try {
        const res = await api.post(
          `/companies/upload-documents/${id}`,
          formData,
        );
        setCompanyData(res.data.data);
        toast.success('Arquivo enviado com sucesso!');
        setLoadingStatus(0);
      } catch (error) {
        setLoadingStatus(0);
        toast.error('Ops! Não foi possível enviar o arquivo. Tente novamente!');
      }
    },
    [setCompanyData],
  );

  return { companyData, loadingStatus, uploadFile };
};

export default useCompanyFileUpload;
