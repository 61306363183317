import React from 'react';
import { FiX } from 'react-icons/fi';
import { CSSProperties } from 'styled-components';
import { Container } from './styles';
import Button from '../Button';

interface WarningBarProps {
  buttonName?: string;
  message?: string;
  buttonClick?: () => void;
  closeBarOnClick?: () => void;
  type: 'danger' | 'warning';
  className?: string;
  style?: CSSProperties;
}

const WarningBar: React.FC<WarningBarProps> = ({
  buttonName,
  type,
  buttonClick,
  message,
  className,
  style,
  closeBarOnClick,
}) => {
  return (
    <Container type={type} className={className} style={style}>
      <div className="bar-content">
        <div className="row" style={{ alignItems: 'center' }}>
          <p className="bar-message">{message}</p>
          {buttonName && (
            <Button className="bar-button" onClick={buttonClick}>
              {buttonName}
            </Button>
          )}
        </div>
        <Button
          transparent
          className="bar-close-button"
          onClick={closeBarOnClick}
        >
          {type === 'danger' && <FiX color="#fff" size={20} />}
          {type === 'warning' && <FiX color="#000" size={20} />}
        </Button>
      </div>
    </Container>
  );
};

export default WarningBar;
