import React, { useCallback, useState } from 'react';
import { FiXCircle } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { Container } from './styles';
import Button from '../../../../components/Button';
import Loader from '../../../../components/Loader';
import api from '../../../../services/api';

interface CompanyDeleteProps {
  id?: string;
  closeModal?: any;
}

const CompanyDeleteContent: React.FC<CompanyDeleteProps> = ({
  id,
  closeModal,
}) => {
  const [loading, setLoading] = useState(0);

  const handleDeleteCompany = useCallback(async () => {
    try {
      setLoading(1);
      await api.delete(`/companies/${id}`);
      setLoading(0);

      closeModal('delete');
      toast.success('Empresa excluida com sucesso!');
    } catch (error) {
      setLoading(0);
    }
  }, [closeModal, id]);
  return (
    <Container>
      <Loader loading={loading} />

      <FiXCircle size={50} color="#ff4c52" />
      <p className="question">Você tem certeza?</p>
      <p className="phrase">Deseja excluir essa empresa?</p>
      <div className="buttons-container">
        <Button className="cancel-button" onClick={() => closeModal('delete')}>
          Cancelar
        </Button>
        <Button className="delete-button" onClick={handleDeleteCompany}>
          Confirmar
        </Button>
      </div>
    </Container>
  );
};

export default CompanyDeleteContent;
