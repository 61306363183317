import React, { useState,useEffect } from 'react';
import { Container } from './styles';

interface SwitchInputProps {
  onChange?: (arg0: any) => void;
  className?: string;
  defaultValue?: boolean;
  id?: string;
  name?: string;
  disable?:boolean;
}

const SwitchInput: React.FC<SwitchInputProps> = ({
  onChange,
  className,
  id,
  defaultValue,
  name,
  disable
}) => {
  const [isSwitch, setIsSwitch] = useState<boolean>(defaultValue || false);
  
  useEffect(()=>{
    setIsSwitch(defaultValue||false);
  },[defaultValue]);

  return (
    <Container className={className} disable={disable||false}>
      <label className="switch" htmlFor={id}>
        <input
          type="checkbox"
          id={id}
          checked={isSwitch}
          onClick={() => setIsSwitch(!isSwitch)}
          onChange={onChange}
          name={name}
          disabled={disable||false}
        />
        <span className="slider round" />
      </label>
    </Container>
  );
};

export default SwitchInput;
