import React from 'react';
import { Title } from './styles';

interface TitleProps {
  primary?: boolean;
  secondary?: boolean;
  children?: string;
  className?: string;
}

const TitleComponent: React.FC<TitleProps> = ({
  primary,
  secondary,
  children,
  className,
}) => {
  return (
    <Title primary={primary} secondary={secondary} className={className}>
      {children}
    </Title>
  );
};

export default TitleComponent;
