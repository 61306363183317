import React, { InputHTMLAttributes } from 'react';

import ErrorMessage from '../ErrorMessage';

import { Container, Label, Input, CheckIcon, PendingIcon } from './styles';

interface InputCpfProps extends InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  className: string;
  inputClassName?: string;
  name: string;
  placeholder?: string;
  value?: string;
  label?: string;
  type?: string;
  error?: string;
  disabled?: boolean;
  mask: string;
  status?: number;
  errorMessage?: string | React.ReactNode;
  register?: any;
  onChange?: any;
  setValue?: any;
}

const InputCpf: React.FC<InputCpfProps>  = (props) => {

  const normalizeCpf = (str: string) => {

    return str
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada

  };

  return (
    <Container className={props.className}>
      {props.label && <Label htmlFor={props.id}>{props.label}</Label>}
      {props.status === 1 && <CheckIcon />}
      {props.status === 0 && <PendingIcon />}

      <Input
        placeholder="000.000.000-00"
        className={props.inputClassName}
        type={props.type}
        disabled={props.disabled}
        name={props.name}
        onChange={(event) => {
          const { value } = event.target;
          event.target.value = normalizeCpf(value);
        }}
        ref={props.register}
      />

      {props.errorMessage && (
        <ErrorMessage marginTop="7px">{props.errorMessage}</ErrorMessage>
      )}
    </Container>
  );
};

export default InputCpf;
