import React from 'react';
import { Container, Row, CancelButton, Button, RemoveButton } from './styles';

interface FormProps {
  className?: string;
  onSubmit?: (e: any) => void;
  onSaveClick?: (e: any) => void;
  onCancelClick?: (e: any) => void;
  onRemoveClick?: () => void;
  cancelButton?: boolean;
  removeButton?: boolean;
  saveButton?: boolean;
  submitButton?: boolean;
  hideSubmitButton?:boolean;
  labelSubmit?:string;
}

const Form: React.FC<FormProps> = ({
  children,
  className,
  onSubmit,
  onSaveClick,
  onCancelClick,
  onRemoveClick,
  cancelButton,
  removeButton,
  saveButton,
  submitButton,
  hideSubmitButton,
  labelSubmit
}) => (
  <Container className={className} onSubmit={onSubmit}>
    {children}

    {(cancelButton || removeButton || saveButton || submitButton) && (
      <Row>
        {cancelButton && (
          <CancelButton type="button" onClick={onCancelClick}>
            Cancelar
          </CancelButton>
        )}
        {removeButton && (
          <RemoveButton type="button" onClick={onRemoveClick}>
            Excluir
          </RemoveButton>
        )}
        {saveButton && (
          <Button type="button" onClick={onSaveClick}>
            Salvar
          </Button>
        )}
        {submitButton && !hideSubmitButton && <Button type="submit">{labelSubmit?labelSubmit:'Salvar'}</Button>}
      </Row>
    )}
  </Container>
);

export default Form;
