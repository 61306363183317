/* eslint-disable eqeqeq */
import React from 'react';

import { toast } from 'react-toastify';
import ErrorMessage from '../ErrorMessage';

import { Container, Label, Input, CheckIcon, PendingIcon } from './styles';

interface InputCepProps {
  id?: string;
  className: string;
  inputClassName?: string;
  name: string;
  placeholder?: string;
  value?: string;
  label?: string;
  type?: string;
  error?: string;
  disabled?: boolean;
  mask: string;
  status?: number;
  errorMessage?: string;
  register?: any;
  onChange?: any;
  setValue?: any;
  setLoading?: any;
}

const InputCep: React.FC<InputCepProps> = (props) => {
  function searchCep(cep: string) {
    props.setLoading(1);
    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((response) => response.json())
      .then((data) => {
        if (data.erro == true) {
          props.setLoading(0);
          toast.error('Cep não encontrado');
          props.setValue('street', '');
          props.setValue('city', '');
          props.setValue('state', '');
          props.setValue('neighborhood', '');
          props.setValue('complement', '');
          props.setValue('number', '');
        } else {
          props.setValue('street', data.logradouro);
          props.setValue('city', data.localidade);
          props.setValue('state', data.uf);
          props.setValue('neighborhood', data.bairro);
          props.setValue('complement', '');
          props.setValue('number', '');
          props.setLoading(0);
        }
      })
      .catch((err) => {
        toast.error('Cep não encontrado');
        props.setLoading(0);
      });
  }

  const normalizeCep = (str: string) => {
    const cep = str.replace(/\D/g, '');
    const re = /^([\d]{2})([\d]{3})([\d]{3})|^[\d]{2}.[\d]{3}-[\d]{3}/;

    if (cep != '') {
      const validacep = /^[0-9]{8}$/;
      if (validacep.test(cep)) {
        const cep_success = str.replace(re, '$1$2-$3');
        searchCep(cep_success);
        return cep_success;
      }
      return str;
    }
    return str;
  };

  return (
    <Container className={props.className}>
      {props.label && <Label htmlFor={props.id}>{props.label}</Label>}
      {props.status === 1 && <CheckIcon />}
      {props.status === 0 && <PendingIcon />}

      <Input
        placeholder="0000-000"
        className={props.inputClassName}
        type={props.type}
        disabled={props.disabled}
        name={props.name}
        onChange={(event) => {
          const { value } = event.target;
          event.target.value = normalizeCep(value);
        }}
        ref={props.register}
      />

      {props.errorMessage && (
        <ErrorMessage marginTop="7px">{props.errorMessage}</ErrorMessage>
      )}
    </Container>
  );
};

export default InputCep;
