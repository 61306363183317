import styled, { css } from 'styled-components';
interface ViewProps {
  isView?:boolean;
}
export const AccordionSection = styled.div`
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #f3f3f3;
`;
export const AccordionHeader = styled.div`    
    color: #444;
    cursor: pointer;
    padding: 10px 0px 2px;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    transition: background-color 0.6s ease;
    border-bottom: 1px solid #f3f3f3;
`;
export const AccordionContent = styled.div`
    background-color: white;
    overflow: auto;
    transition: max-height 0.6s ease;
`;

export const Container = styled.div`
    width:100%;
    position:absolute;        
    border-top:1px solid #f3f3f3;    
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0px;
  margin-bottom: 10px;

  .switch-input {
    margin-right: 16px;
  }

  .switch-label {
    margin-right: 10px;
  }
`;

export const Dropdown = styled.div`

`;

export const Col10 = styled.div`
  flex:0.1;
`;
export const Col25 = styled.div`
  justify-content: center;
  display: flex;
  flex:0.25;
`;
export const Col40 = styled.div<ViewProps>`
  flex:0.4;
  @media (max-width: 769px) {
    flex:${ props => props.isView ? 1.0:1.5}};
  }
  
`;