import styled from 'styled-components';

export const Container = styled.div`
  .switch-row {
    margin-top: 30px;
  }

  .notification {
    margin-top: 28px;
  }
`;

export const SwitchContainer = styled.div`
  align-items: center;
  padding: 0 15px;
  margin-bottom: 30px;

  .switch-input {
    margin-right: 16px;
  }

  .switch-label {
    margin-bottom: 10px;
  }
`;
