import styled from 'styled-components';

interface CardProps {
  padding?: string;
  width?: string;
  margin?: string;
}

export const Container = styled.div<CardProps>`
  position: relative;
  background-color: #fff;
  border-radius: 20px;
  padding: ${({ padding }) => padding || '30px'};
  max-width: ${({ width }) => width || '714px'};
  margin: ${({ margin }) => margin || 'auto'};
  box-shadow: 0 10px 20px rgba(14, 40, 93, 0.09);

  .card-title {
    font-size: 20px;
    margin-bottom: 20px;
  }
`;
