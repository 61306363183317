import React from 'react';
import {
  Container,
  DropdownContainer,
  VerticalThreeDots,
  MenuList,
} from './styles';
import Dropdown from '../Dropdown';
import Card from '../Card';

interface ContentBoxProps {
  className?: string;
  menuList?: any[];
  onClick?: () => void;
}

const ContentBox: React.FC<ContentBoxProps> = ({
  children,
  className,
  menuList,
  onClick,
}) => {
  return (
    <Container className={className} onClick={onClick}>
      <DropdownContainer className="dropdown-container">
        {!!menuList && (
          <Dropdown trigger={<VerticalThreeDots />}>
            <Card>
              <ul>
                {menuList?.map((list) => (
                  <MenuList
                    id={list.id}
                    key={list.id}
                    onClick={() => list.method()}
                  >
                    {list.name}
                  </MenuList>
                ))}
              </ul>
            </Card>
          </Dropdown>
        )}
      </DropdownContainer>
      {children}
    </Container>
  );
};

export default ContentBox;
