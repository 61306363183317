import styled, { css } from 'styled-components';
interface ViewProps {
  isView?:boolean;
}
export const ListSection = styled.div`
  display: flex;
  flex-direction: column;
  width:100%;
`;
export const ListContainer = styled.div`  
    color: #444;
    cursor: pointer;
    padding: 7px 0px 7px;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    transition: background-color 0.6s ease;
    width:100%;
`;

export const SwitchContainer = styled.div`

  display: flex;
  align-items: center;
  padding: 0 14px;
  margin-bottom: 10px;

  .switch-input {
    margin-right: 16px;
  }

  .switch-label {
    margin-right: 10px;
    flex:1;
  }
`;

export const TitleItem = styled.div`
    margin-left:22%;
`
export const Dropdown = styled.div`
`;

export const Col10 = styled.div`
  flex:0.1;
`;
export const Col25 = styled.div`
  justify-content: center;
  display: flex;
  flex:0.25;
`;

export const Col40 = styled.div<ViewProps>`
  flex:0.4;
  @media (max-width: 769px) {
    flex:${ props => props.isView ? 0.9:1.5}};
  }
  
`;