import React from 'react';
import { Container, DeviceType } from './styles';
import phoneMockupIcon from '../../../../assets/phone-mockup.svg';
import laptopMockupIcon from '../../../../assets/laptop-mockup.svg';

const ActiveDevices: React.FC = () => {
  // const dropdownMenuList = [
  //   {
  //     id: 1,
  //     name: 'Bloquear',
  //     method: blockDevice,
  //   },
  //   {
  //     id: 2,
  //     name: 'Remover',
  //   },
  // ];
  return (
    <Container cardTitle="Dispositivos ativos (Em breve)">
      <div
        className="row"
        style={{ margin: '0', justifyContent: 'space-between' }}
      >
        {/* <p className="coming-soon-message">Em breve</p> */}
        <DeviceType>
          <div className="mockup">
            <img src={phoneMockupIcon} alt="" />
          </div>
          <p className="device-name">iPhone X</p>
          <p className="device-owner">iPhone de Jo**</p>
          <p className="last-access">Último acesso em 23.10.2020 às 22:30</p>
        </DeviceType>
        <DeviceType>
          <div className="mockup">
            <img src={laptopMockupIcon} alt="" />
          </div>
          <p className="device-name">Macbook Pro</p>
          <p className="device-owner">Macbook de Ma****</p>
          <p className="last-access">Último acesso em 23.10.2020 às 22:30</p>
        </DeviceType>
        <DeviceType>
          <div className="mockup">
            <img src={laptopMockupIcon} alt="" />
          </div>
          <p className="device-name">Dell</p>
          <p className="device-owner">Notebook de La**</p>
          <p className="last-access">Último acesso em 23.10.2020 às 22:30</p>
        </DeviceType>
      </div>
    </Container>
  );
};

export default ActiveDevices;
