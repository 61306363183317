import styled from 'styled-components';
import usernameImg from '../../assets/img/highfive.jpeg';
import computerImg from '../../assets/img/computer.jpeg';
import phoneImg from '../../assets/img/phone.jpeg';
import safeboxImg from '../../assets/img/safebox.jpeg';
import cpfBackground from '../../assets/backgrounds/id-card-animate.svg';
import zipcodeBackground from '../../assets/backgrounds/navigation-animate.svg';
import residenceDocBackground from '../../assets/backgrounds/upload-animate.svg';
import idDocBackground from '../../assets/backgrounds/image-upload-animate.svg';
import selfieBackground from '../../assets/backgrounds/photo-animate.svg';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;
`;

export const DocContainer = styled.div`
  height: 100vh;
  align-items: stretch;
`;

export const ContentContainer = styled.div`
  height: calc(100% - 84px);
  display: flex;
  align-items: stretch;
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  width: 100%;
  max-width: 700px;

  img {
    margin-top: 80px;
    margin-bottom: 55px;
  }
`;

export const DocContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 700px;
  padding-top: 76px;
`;

export const BackgroundImg = styled.div`
  display: flex;
  width: 100%;
`;

export const UsernameBackground = styled.div`
  flex: 1;
  background: url(${usernameImg}) no-repeat center;
  background-size: cover;
`;

export const ComputerBackground = styled.div`
  flex: 1;
  background: url(${computerImg}) no-repeat center;
  background-size: cover;
`;

export const PhoneBackground = styled.div`
  flex: 1;
  background: url(${phoneImg}) no-repeat center;
  background-size: cover;
`;

export const SafeboxBackground = styled.div`
  flex: 1;
  background: url(${safeboxImg}) no-repeat center;
  background-size: cover;
`;

export const CpfBackground = styled.div`
  flex: 1;
  background: url(${cpfBackground}) no-repeat center;
`;

export const ZipcodeBackground = styled.div`
  flex: 1;
  background: url(${zipcodeBackground}) no-repeat center;
`;

export const ResidenceDocBackground = styled.div`
  flex: 1;
  background: url(${residenceDocBackground}) no-repeat center;
`;

export const IdDocBackground = styled.div`
  flex: 1;
  background: url(${idDocBackground}) no-repeat center;
`;

export const SelfieBackground = styled.div`
  flex: 1;
  background: url(${selfieBackground}) no-repeat center;
`;
