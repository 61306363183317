import styled from 'styled-components';
import ContentBox from '../../../../components/ContentBox';
import * as color from '../../../../styles/colors';

export const Container = styled.div`
  position: relative;

  .goback-button {
    margin-bottom: 20px;
    p {
      color: ${color.orange};
    }
  }
`;

export const CompanyBox = styled(ContentBox)`
  display: flex;
  padding: 20px;
  margin-bottom: 20px;
  img {
    margin-right: 28px;
    width: 40px;
    height: 40px;
  }

  .dropdown-container {
    top: 10px;
  }
`;

export const Content = styled.div`
  display: block;
  width: 100%;

  .header {
    font-weight: bold;
  }

  /* .register-date {
    font-size: 12px;
  } */

  .company-footer-row {
    margin: 15px 0 0;

    .credit-card-tax {
      display: flex;
      align-items: center;
    }

    .company-update-button {
      text-align: right;

      button {
        height: 38px;
      }
    }
  }

  .service-status {
    margin-top: 15px;
  }

  .company-name {
    padding-right: 10px;
  }

  .tax-type {
    /* height: 0; */
    padding: 0;
    .accounts__control {
      height: 0;

      .accounts__single-value {
        top: 50%;
      }
    }
  }

  .credit-card-tax-rate {
    font-size: 12px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
