import React from 'react';
import { Container, ModalContent, CloseButton } from './styles';
import closeIcon from '../../assets/x.svg';

interface ModalProp {
  isOpen: boolean;
  contentLabel?: string;
  headerLabel?: string;
  style?: {};
  onRequestClose?: () => void;
  onAfterOpen?: () => void;
  closeModal?: () => void;
  smallWidth?: boolean;
}
const ModalAdmin: React.FC<ModalProp> = ({
  children,
  isOpen,
  contentLabel,
  style,
  headerLabel,
  closeModal,
  onRequestClose,
  onAfterOpen,
  smallWidth,
}) => {
  return (
    <Container
      isOpen={isOpen}
      style={style}
      smallWidth={smallWidth}
      contentLabel={contentLabel}
      onRequestClose={onRequestClose}
      onAfterOpen={onAfterOpen}
      className="modal-component"
      overlayClassName="Modal-Overlay"
      closeTimeoutMS={500}
      ariaHideApp={false}
    >
      <ModalContent>
        <CloseButton type="button" onClick={closeModal}>
          <img src={closeIcon} alt="" />
        </CloseButton>
        {headerLabel && <p className="title">{headerLabel}</p>}

        {children}
      </ModalContent>
    </Container>
  );
};

export default ModalAdmin;
