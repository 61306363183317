import styled from 'styled-components';

interface DropdownProps {
  show?: boolean;
}

export const Container = styled.div`
  position: relative;
`;

export const DropdownMenu = styled.div<DropdownProps>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: absolute;
  /* bottom: 0; */
`;

export const Button = styled.button`
  background: transparent;
  border: none;
  padding: 5px;
  display: flex;
  align-items: center;
`;
