import React from 'react';
// import { FiCheck } from 'react-icons/fi';
import { ValidationRule, UseFormMethods } from 'react-hook-form';
import {
  Container,
  Label,
  Input,
  CheckIcon,
  PendingIcon,
  Masked,
} from './styles';
import ErrorMessage from '../ErrorMessage';

interface AdminInputProps
  extends Partial<Pick<UseFormMethods, 'register' | 'errors'>> {
  id?: string;
  className?: string;
  inputClassName?: string;
  name?: string;
  placeholder?: string;
  value?: string;
  label?: string;
  type?: string;
  error?: string;
  width?: string;
  widthQuery?: string;
  disabled?: boolean;
  mask: string;
  status?: number;
  errorMessage?: string | JSX.Element;
  onChange?: (arg0: any) => void;
  rules?: ValidationRule;
}

const AdminInput: React.FC<AdminInputProps> = ({
  id,
  className,
  inputClassName,
  name,
  placeholder,
  value,
  type,
  label,
  disabled,
  onChange,
  register,
  rules = {},
  errors = {},
  mask,
  status,
  errorMessage,
  ...rest
}) => {
  return (
    <Container className={className}>
      {label && <Label htmlFor={id}>{label}</Label>}
      {status === 1 && <CheckIcon />}
      {status === 0 && <PendingIcon />}

      <Masked
        mask={mask}
        alwaysShowMask
        onChange={onChange}
        disabled={disabled}
        value={value}
      >
        {() => (
          <Input
            ref={register}
            status={status}
            name={name}
            id={id}
            placeholder={placeholder}
            className={inputClassName}
            type={type}
          />
        )}
      </Masked>
      {status === 0 && (
        <ErrorMessage marginTop="7px">{errorMessage}</ErrorMessage>
      )}
    </Container>
  );
};

export default AdminInput;
