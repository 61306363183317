import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { isAuthenticated } from '../services/auth.js';

const PrivateRoute: React.FC<{
  component: React.FC;
  path: string;
  exact: boolean;
}> = ({ component, path, exact, ...rest }) => {
  const condition = isAuthenticated();

  return condition ? (
    <Route path={path} exact={exact} component={component} {...rest} />
  ) : (
    <Redirect to="/" />
  );
};
export default PrivateRoute;
