import React from 'react';
import { OptionTypeBase } from 'react-select';
import { Container, SelectInput, Label } from './styles';
import ErrorMessage from '../ErrorMessage';

interface SelectProps extends OptionTypeBase {
  id?: string;
  selectClassName?: string;
  name?: string;
  placeholder?: string;
  value?: any;
  label?: string;
  disabled?: boolean;
  register?: any;
  onChange?: (arg0: any) => void;
  options?: Array<object>;
  error?: string;
}

const Select: React.FC<SelectProps> = ({
  id,
  className,
  selectClassName,
  name,
  value,
  label,
  disabled,
  onChange,
  options,
  errorMessage,
  register,
}) => {
  return (
    <Container className={className}>
      {label && <Label htmlFor={id}>{label}</Label>}
      <SelectInput
        id={id}
        name={name}
        placeholder="Selecionar"
        className={selectClassName}
        value={value}
        isDisabled={disabled}
        onChange={onChange}
        classNamePrefix="accounts"
        options={options}
        ref={register}
      />

      {errorMessage && (
        <ErrorMessage marginTop="7px">{errorMessage}</ErrorMessage>
      )}
    </Container>
  );
};

export default Select;
