import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { FiCheck } from 'react-icons/fi';
import {
  UploadBoxContainer,
  FileUploadedBox,
  TrashButton,
  FileName,
  FileListBox,
  Container,
} from './styles';

import fileJPGIcon from '../../assets/file-JPG.svg';
import filePNGIcon from '../../assets/file-PNG.svg';
import filePDFIcon from '../../assets/file-PDF.svg';

interface UploadBoxProps {
  wholeFile?: any;
  fileArray?: any;
  removeFile?: () => void;
  fileType?: string;
  fileName?: string;
  fileSize?: string;
  id?: string;
  className?: string;
  disabled?: boolean;
  uploadStatus?: boolean;
}

const UploadBox: React.FC<UploadBoxProps> = ({
  wholeFile,
  fileType,
  fileName,
  id,
  className,
  removeFile,
  disabled,
  uploadStatus,
}) => {
  const onDrop = useCallback(
    (files) => {
      files.map((f: any) => {
        return wholeFile(f);
      });
    },
    [wholeFile],
  );
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone({ onDrop, multiple: true, disabled });

  const files = (
    <FileName key={fileName}>
      {fileType === 'png' && <img key={id} src={filePNGIcon} alt="" />}
      {(fileType === 'jpeg' || fileType === 'jpg') && <img key={id} src={fileJPGIcon} alt="" />}
      {fileType === 'pdf' && <img key={id} src={filePDFIcon} alt="" />}
      <div key={fileName}>
        <p>{fileName}</p>
        {/* <p>{fileSize}Bytes</p> */}
      </div>
    </FileName>
  );

  return (
    <>
      {fileName ? (
        <FileUploadedBox>
          {files}
          <TrashButton onClick={removeFile} />
        </FileUploadedBox>
      ) : (
        <>
          <Container disabled={disabled}>
            <UploadBoxContainer
              className={className}
              {...getRootProps({
                isDragActive,
                isDragAccept,
                isDragReject,
              })}
            >
              <input {...getInputProps()} id={id} />
              <p>
                Arraste e solte o arquivo aqui ou clique para{' '}
                <span>fazer upload</span>
              </p>
            </UploadBoxContainer>
          </Container>
          <p style={{ marginTop: '5px', fontSize: '11px' }}>
            *Tipos de arquivos aceitos para upload: PNG, JPG/JPEG e PDF
          </p>
          {acceptedFiles.length >= 1 && (
            <FileListBox>
              {acceptedFiles.map((f) =>
                uploadStatus ? (
                  <div
                    key={f.name}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <p
                      key={f.name}
                      style={{ color: '#00be9b', marginRight: '10px'}}
                    >
                      {f.name}
                    </p>
                    <FiCheck color="#00be9b" size={20} />
                  </div>
                ) : (
                  <p key={f.name}>{f.name}</p>
                ),
              )}
            </FileListBox>
          )}
        </>
      )}
    </>
  );
};

export default UploadBox;
