import styled from 'styled-components';
import Card from '../../../../../components/Card';

export const Container = styled(Card)`
  margin-top: 28px;
`;

export const SectionTitle = styled.h4`
  font-size: 20px;
  font-weight: bold;
  margin: 14px 0 14px;
  padding: 0 20px;
`;
