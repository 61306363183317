import styled from 'styled-components';
import Card from '../../../../components/Card';
import ContentBox from '../../../../components/ContentBox';

export const Container = styled(Card)``;

export const DeviceType = styled(ContentBox)`
  height: 154px;
  width: 203px;
  background: #f1f1f1;

  .mockup {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    margin-bottom: 10px;
  }

  .device-name {
    font-weight: bold;
    margin-bottom: 5px;
  }

  .device-owner {
    margin-bottom: 12px;
  }

  .last-access {
    font-size: 10px;
  }

  @media (max-width: 546px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;
