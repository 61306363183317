import homeIcon from '../assets/home.svg';
import userIcon from '../assets/user.svg';
import securityLockIcon from '../assets/security-lock.svg';
import userPlusIcon from '../assets/user-plus.svg';
// import moneyBagIcon from '../assets/money-bag.svg';
import suitcaseIcon from '../assets/suitcase.svg';
import squaresIcon from '../assets/squares.svg';

export const menuList = [
  {
    id: 1,
    name: 'Início',
    to: '/home',
    icon: homeIcon,
    role: 'all',
  },
  {
    id: 2,
    name: 'Informações pessoais',
    to: '/personal-info',
    icon: userIcon,
    role: 'all',
  },
  {
    id: 3,
    name: 'Segurança',
    to: '/security',
    icon: securityLockIcon,
    role: 'all',
  },
  {
    id: 4,
    name: 'Pessoas e acessos',
    to: '/people-and-access',
    icon: userPlusIcon,
    role: 'account-owner',
  },
  // {
  //   id: 5,
  //   name: 'Dados bancários',
  //   to: '/bank-info',
  //   icon: moneyBagIcon,
  // },
  {
    id: 6,
    name: 'Empresas',
    to: '/companies',
    icon: suitcaseIcon,
    role: 'account-owner',
  },
  {
    id: 7,
    name: 'Serviços',
    to: '/services',
    icon: squaresIcon,
    role: 'account-owner',
  },
];
