import { useState, useEffect } from 'react';

import { IUser } from '../entities/User';
import { ICompany } from '../entities/Company';

export default function useAccounts() {
  const [user, setUser] = useState<IUser | undefined>(undefined);
  const [company, setCompany] = useState<ICompany | undefined>(undefined);
  const [forceToReload, setForceToReload] = useState<number>(0);
  const [
    forceToReloadBankComponent,
    setForceToReloadBankComponent,
  ] = useState<number>(0);
  const [
    disableUploadPersonalDocuments,
    setDisableUploadPersonalDocuments,
  ] = useState<boolean>(false);
  const [
    disableUploadResidenceProveDocuments,
    setDisableUploadResidenceProveDocuments,
  ] = useState<boolean>(false);
  const [
    disableUploadCompanyAddressProveDocuments,
    setDisableUploadCompanyAddressProveDocuments,
  ] = useState<boolean>(false);
  const [
    disableUploadCompanyBankAccountDocuments,
    setDisableUploadCompanyBankAccountDocuments,
  ] = useState<boolean>(false);
  const [
    disableUploadCompanyDocuments,
    setDisableUploadCompanyDocuments,
  ] = useState<boolean>(false);

  const [mobileMenuToggle, setMobileMenuToggle] = useState<boolean>(false);
  const [uploadSuccess, setUploadSuccess] = useState<boolean>(false);
  const [siriusLoadingSync, setSiriusLoadingSync] = useState<number>(0);

  useEffect(() => {
    const userString = localStorage.getItem('user');
    if (userString) setUser(JSON.parse(userString));
  }, []);

  useEffect(() => {
    if (user) localStorage.setItem('user', JSON.stringify(user));
  }, [user]);

  const [lastVerificationPixKey, setLastVerificationPixKey] = useState<string>()  
  
  useEffect(() => {
    const lastPixKeyVerificationString = localStorage.getItem('lastPixKeyVerification');
    if(lastPixKeyVerificationString) setLastVerificationPixKey(JSON.parse(lastPixKeyVerificationString))
  }, [])

  useEffect(() => {
    if(lastVerificationPixKey) localStorage.setItem('lastPixKeyVerification', JSON.stringify(lastVerificationPixKey))
  }, [lastVerificationPixKey])

  return {
    user,
    setUser,
    company,
    setCompany,
    forceToReload,
    setForceToReload,
    disableUploadPersonalDocuments,
    setDisableUploadPersonalDocuments,
    disableUploadResidenceProveDocuments,
    setDisableUploadResidenceProveDocuments,
    disableUploadCompanyAddressProveDocuments,
    setDisableUploadCompanyAddressProveDocuments,
    disableUploadCompanyBankAccountDocuments,
    setDisableUploadCompanyBankAccountDocuments,
    disableUploadCompanyDocuments,
    setDisableUploadCompanyDocuments,
    forceToReloadBankComponent,
    setForceToReloadBankComponent,
    mobileMenuToggle,
    setMobileMenuToggle,
    uploadSuccess,
    setUploadSuccess,
    siriusLoadingSync,
    setSiriusLoadingSync,
    lastVerificationPixKey,
    setLastVerificationPixKey,
  };
}
